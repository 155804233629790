import React, {useEffect} from 'react';
import shallow from 'zustand/shallow';
import Helmet from 'react-helmet';
import {Link} from 'gatsby';
import Layout from '../components/Layout';
import NotFoundGif from '../assets/images/pic12.gif';
import {usePhotoStore} from '../store';

let longProjectDescription;

const RenderProjectAssets = ({currentProject}) => {
  const projectAssets = currentProject.assets;

  if (projectAssets) {
    const [firstAsset, ...otherAssets] = projectAssets;
    longProjectDescription = firstAsset.fields.description;

    return otherAssets.map((assetItem) => {
      const objAssets = {
        itemDescription: assetItem.fields.description || 'Item description',
        itemTitle: assetItem.fields.title || 'Item title',
        itemImage: assetItem.fields.file.url || 'Item image',
      };

      return (
        <section key={objAssets.itemTitle}>
          <Link className="image hover-description" to="#">
            <img src={objAssets.itemImage} alt={objAssets.itemTitle} />
          </Link>
          <div className="content project">
            <div className="inner">
              <header className="major">
                <h2>{objAssets.itemTitle}</h2>
                {/* <p>{objAssets.itemDescription}</p> */}
              </header>
              {/* <p>Describe as best as you can your project idea</p> */}
              {/* <ul className="actions">
                                <li><Link href="/#"><a className="button">Learn more (WHAT COULD BE DONE WITH THIS?)</a></Link></li>
                            </ul> */}
            </div>
          </div>
        </section>
      );
    });
  }

  return null;
};

const Project = ({location}) => {
  const {getCurrentProject, getProjectsInfo, setCurrentProject, currentProject} = usePhotoStore(
    (state) => ({
      getCurrentProject: state.getCurrentProject,
      getProjectsInfo: state.getProjectsInfo,
      setCurrentProject: state.setCurrentProject,
      currentProject: state.currentProject,
    }),
    shallow
  );

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getCurrentProject();
    }

    return () => {
      mounted = false;
    };
  }, [getCurrentProject]);

  useEffect(() => {
    let mounted = true;
    const projectName = unescape(location.search.substring(2));
    const projects = getProjectsInfo();

    function setCurrentProjectInfo() {
      const [newCurrentProject] = projects.filter((curProject) => {
        return curProject.projectName === projectName;
      });

      setCurrentProject(newCurrentProject);
    }

    if (mounted && !currentProject) {
      setCurrentProjectInfo();
    }

    return () => {
      mounted = false;
    };
  }, [currentProject, getProjectsInfo, location.search, setCurrentProject]);

  return currentProject ? (
    <Layout>
      <Helmet
        title={currentProject.projectDescription || 'Projects'}
        meta={[
          {
            name: 'description',
            content: longProjectDescription || 'Projects',
          },
        ]}
      />
      <div id="main" className="alt">
        <section id="two" className="spotlights">
          <RenderProjectAssets currentProject={currentProject} />
        </section>
      </div>
    </Layout>
  ) : (
    <Layout>
      <Helmet
        title="Project unavailable!"
        meta={[
          {
            name: 'description',
            content: 'Project unavailable!',
          },
        ]}
      />
      <div id="main" className="alt">
        <section id="two" className="spotlights">
          <section>
            <img src={NotFoundGif} width="30%" alt="Sorry!" />
            <div className="content">
              <div className="inner">
                <header className="major">
                  <h1>Project unavailable.</h1>
                </header>
                <p>Please send me a message regarding this issue.</p>
                <ul className="actions">
                  <li>
                    <Link className="button" to="#contact">
                      Click here to send me an e-mail!
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </section>
      </div>
    </Layout>
  );
};

export default Project;
